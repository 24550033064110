const PaymentURLScreen = () => {

    const url = "https://sud.life/BLKF8LVTN1";

    return (<><embed id="iFrameExample"
        title="iFrame Example" src={url} width={"100%"} height={"100%"}>
    </embed>
        <br></br>
        <iframe id="iFrameExample"
            title="iFrame Example" src={url} width={"100%"} height={"100%"}>
        </iframe>
    </>)
}

export default PaymentURLScreen;