export const AUTH_ROUTES = {
	LOGIN: '/',
	LOGIN_OTP_VERIFY: '/login/otp-verify',
	SIGNUP: '/signup',
	SIGNUP_OTP_VERIFY: '/signup/otp-verify',
	SIGNUP_VERIFICATION_STATUS: '/signup/verification-status',
	EKYC_OTPSCREEN: '/ekyc-otpscreen',
	EKYC_IN_CAMS_OTPSCREEN: '/ekyc-in-cams-otpscreen',
	SIGNUP_EKYC_DETAILS_ONE: '/signup-ekyc-detailone',
	AUTHORISED_REPRESENTATIVE: '/authorised-representat',
	SIGNUP_CASE_TWO: '/eia-others',
	SIGNUP_KYC_RETURN_URL: '/signup/kyc-verification',
	ADD_POLICY_INSTRUCTION: "/add-policy-instruction",
	ADD_POLICY_STEP_ONE: "/stepone",
	ADD_POLICY_STEP_TWO_INSTRUCTION: "/steptwo-instruction",
	ADD_POLICY_STEP_TWO: "/steptwo",
	ADD_POLICY_SUBMITTION_PREVIEW: "/add-policy-summary",
};

export const APP_ROUTES = {
	DASHBOARD: '/',
	POLICY: '/policy',
	ADDPOLICY: '/policy/add',
	ASSIGN_POLICY: '/policy/assign',
	POLICY_DETAILS: '/policy/details',
	POLICY_DETAILS_LIFE: '/policy/details/life',
	POLICY_DETAILS_HEALTH: '/policy/details/health',
	POLICY_DETAILS_ASSET: '/policy/details/asset',
	PROFILE: '/profile',
	FAMILYDETAILS: '/familydetails',
	DASHBOARDSCREEN: '/dashboard',
	ADD_FAMILY_SCREEN: '/addfamilyscreen/:title',
	UPDATE_MOBILE: '/update-mobile',
	NEED_MONEY: '/needmoney',
	MY_OPTIONS: '/myoptions',
	ADD_WITNESS_DETAILS: '/add-witness-details',
	RECOMMENDED_SELECTED: '/recommended-combination-selected',
	SUBMIT_LOAN_APPLICATION: '/submit-loan-application',
	TRACK_TRANSACTION: '/track-transaction',
	PREMIUM_PAYMENT: '/premium-payment',
	MANAGE_ADDRESS: '/manage-address',
	SELECT_POLICIES_TO_ASSIGN: '/select-policies-to-assign',
	EMAIL: '/email',
	BANK: '/bank',
	NOMINEE: '/nominee',
	EDITNOMINEE: '/nominee/edit',
	PAYMENTPAGE: '/payment',
	LEGAL: '/legal',
	PAYMENT_SUCCESS: '/payment-feedback/success',
	RENEWAL_PAYMENT_SUCCESS: '/general/payment-feedback/success',
	PAYMENT_FAILED: '/payment-feedback/failed',
	RENEWAL_PAYMENT_FAILED: '/general/payment-feedback/failed',
	PROFILE_AR_SCREEN: '/profile-ar-screen',
	FINANCEPREMIUM: '/finance',
	SUPPORT: '/support',
	COMPLAINTS: '/complaints',
	SUPPORT_SUBMITTED: '/support-submitted',
	TARGET_AMOUNT: '/target-amount',
	Best_Fit_Combinations: '/best-fit-combinations',
	Lender_Screen: '/lender_screen',
	Select_Financier: '/select-financier',
	Get_Loan_Against_Policy: '/get-loan-against-policy',
	Policy_Witness_Details: '/policy-witness-details',
	Policy_Loan_Submit: '/policy-loan-submit',
	Application_Progress: '/application-progress',
	ADDRESS_CHANGE_RETURN_URL: '/manage-address/change-permanent',
	RENEWAL_PAYMENT_SCREEN: '/renewal-payment',
	SELECTBANKDETAILS: '/select-bank',
	SURRENDER_POLICY: '/surrender-policy',
	SELECT_NEED_MONEY: '/select-needmoney',
	ADD_POLICY_INSTRUCTION: "/add-policy-instruction",
	ADD_POLICY_STEP_ONE: "/stepone",
	ADD_POLICY_STEP_TWO_INSTRUCTION: "/steptwo-instruction",
	ADD_POLICY_STEP_TWO: "/steptwo",
	ADD_POLICY_SUBMITTION_PREVIEW: "/add-policy-summary",
	SIGNIN_KYC_RETURN_URL: "/signup/kyc-verification",
	MERGE_INFORMATION: "/merge-information",
	SUD_PG: "Sud-PG"

};

export const PUBLIC_ROUTES = {
	PRIVACYSCREEN: '/privacyscreen/',
	PRIVACYPOLICY: '/privacyPolicy/',
	DISCLAIMER: '/disclaimer/',
	DATAPROTECTIONPOLICY: '/dataProtectionPolicy/',
	SECURITYTIPS: '/securityTips/',
};
