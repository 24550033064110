import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { APP_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
// import PageNotFound from '../common/ui/PageNotFound';
import DashboardScreen from '../features/App/DashboardScreen/DashboardScreen';
import AddPolicyScreen from '../features/App/PolicyScreen/AddPolicy/AddPolicyScreen';
import PolicyScreen from '../features/App/PolicyScreen/PolicyScreen';
import AddFamilyMember from '../features/App/Profile/AddFamilyMember/AddFamilyMember';
import AppLayout from './AppLayout';
import ProfileScreen from '../features/App/Profile/ProfileScreen';
import NeedMoney from '../features/App/LoanModule/NeedMoney';
import MyOptionScreen from '../features/App/LoanModule/LoanOptionScreen';
import AddWitnessDetails from '../features/App/LoanModule/AddWitnessDetails';
import RecommendedCombinationSelected from '../features/App/LoanModule/RecommendedCombinationSelected';
import SubmitLoanApplication from '../features/App/LoanModule/SubmitLoanApplication';

import PaymentScreen from '../features/App/Payment/PaymentScreen';
import AssignPolicyScreen from '../features/App/Common/AssignPoilicy/AssignPolicyScreen';
import MobileNumberScreen from '../features/App/MobileNumber/MobileNumberScreen';
import ManageEmailScreen from '../features/App/Email/ManageEmailScreen';
import NomineeScreen from '../features/App/Nominee/NomineeScreen';
import PaymentFailed from '../features/App/Payment/PaymentFeedbacks/PaymentFailed';
import ManageBankScreen from '../features/App/BankModule/ManageBankScreen';
import ManageAddressScreen from '../features/App/AddressModule/ManageAddressScreen';
import EditNominee from '../features/App/Nominee/NomineeComponents/EditNominee';
import PaymentFeedbacks from '../features/App/Payment/PaymentFeedbacks/PaymentFeedbacks';
import ProfileARscreen from '../features/App/Profile/ProfileARscreen';
import FinancePremium from '../features/App/FInancePremium/FinancePremium';

import HealthDetails from '../features/App/PolicyScreen/common/PolicyDetails/Health/HealthDetails';
import AssetDetails from '../features/App/PolicyScreen/common/PolicyDetails/Asset/AssetDetails';
import SupportMain from '../features/App/Support/SupportMain';
import SupportSubmittedScreen from '../features/App/Support/SupportSubmittedScreen';
import LifeDetails from '../features/App/PolicyScreen/common/PolicyDetails/Life/LifeDetails';
import TrackTransactionScreen from '../features/App/LoanModule/TrackTransaction/TrackTransactionScreen';
import TargetAmount from '../features/App/LoanModule/TargetAmount';
import BestFitCombinations from '../features/App/LoanModule/BestFitCombinations';
import LenderScreen from '../features/App/LoanModule/LenderScreen';
import SelectFinancier from '../features/App/LoanModule/SelectFinanciers';
import GetLoanAgainstPolicy from '../features/App/PolicyScreen/LoanAgainstPolicy/GetLoanAgainstPolicy';
import PolicyWitnessDetails from '../features/App/PolicyScreen/LoanAgainstPolicy/PolicyWitnessDetails';
import PolicyLoanSubmit from '../features/App/PolicyScreen/LoanAgainstPolicy/PolicyLoanSubmit';
import ApplicationProgress from '../features/App/PolicyScreen/LoanAgainstPolicy/ApplicationProgress';
import AddressChangeEKYCReturnComponent from '../features/App/AddressModule/AddressChangeEKYCReturnComponent';
import RenewalPaymentScreen from '../features/App/DashboardScreen/Calendar/RenewalPaymentScreen';
import RenewalPaymentFeedback from '../features/App/Payment/PaymentFeedbacks/RenewalPaymentFeedback';
import SelectBankScreen from '../features/App/LoanModule/SelectBankScreen';
import ManageSurrenderPolicyScreen from '../features/App/SurrenderModule/ManageSurrenderpolicyScreen';
import AddPolicyInstructionScreen from '../features/App/AddPolicyScreen/AddPolicyInstructionScreen';
import SubmissionPreviewScreen from '../features/App/AddPolicyScreen/SubmissionPreviewScreen';
import StepTwoScreen from '../features/App/AddPolicyScreen/StepTwoScreen';
import StepOneScreen from '../features/App/AddPolicyScreen/StepOneScreen';
import StepTwoInstructionScreen from '../features/App/AddPolicyScreen/StepTwoInstructionScreen';
import SignupKycReturnComponent from '../features/Auth/SignUp/SignupKycReturnComponent';
import MergeInformation from '../features/App/Profile/AddFamilyMember/MergeInformation';
import PaymentURLScreen from '../features/App/Payment/PaymentURLScreen';

const AppRoutes = ({ isLogged }: any) => {
	const location = useLocation();
	return (
		<Routes>
			{isLogged === true ? (
				<Route path="/" element={<AppLayout />}>
					<Route path={APP_ROUTES.DASHBOARD} element={<DashboardScreen />} />
					<Route path={APP_ROUTES.SUPPORT} element={<SupportMain />} />
					<Route
						path={APP_ROUTES.SUPPORT_SUBMITTED}
						element={<SupportSubmittedScreen />}
					/>
					<Route path={APP_ROUTES.PROFILE} element={<ProfileScreen />} />
					<Route
						path={APP_ROUTES.UPDATE_MOBILE}
						element={<MobileNumberScreen />}
					/>
					<Route
						path={APP_ROUTES.FINANCEPREMIUM}
						element={<FinancePremium />}
					/>
					<Route path={APP_ROUTES.POLICY} element={<PolicyScreen />} />
					<Route path={APP_ROUTES.ADDPOLICY} element={<AddPolicyScreen />} />
					<Route
						path={APP_ROUTES.POLICY_DETAILS_LIFE}
						element={<LifeDetails />}
					/>
					<Route
						path={APP_ROUTES.POLICY_DETAILS_HEALTH}
						element={<HealthDetails />}
					/>

					<Route
						path={APP_ROUTES.POLICY_DETAILS_ASSET}
						element={<AssetDetails />}
					/>
					<Route
						path={APP_ROUTES.ASSIGN_POLICY}
						element={<AssignPolicyScreen />}
					/>
					<Route
						path={APP_ROUTES.TRACK_TRANSACTION}
						element={<TrackTransactionScreen />}
					/>
					<Route
						path={APP_ROUTES.FAMILYDETAILS}
						element={<ProfileScreen values="Family" />}
					/>
					<Route
						path={APP_ROUTES.ADD_FAMILY_SCREEN}
						element={<AddFamilyMember />}
					/>
					<Route path={APP_ROUTES.NEED_MONEY} element={<NeedMoney />} />
					<Route path={APP_ROUTES.MY_OPTIONS} element={<MyOptionScreen />} />
					<Route
						path={APP_ROUTES.ADD_WITNESS_DETAILS}
						element={<AddWitnessDetails />}
					/>
					<Route
						path={APP_ROUTES.RECOMMENDED_SELECTED}
						element={<RecommendedCombinationSelected />}
					/>
					<Route
						path={APP_ROUTES.SUBMIT_LOAN_APPLICATION}
						element={<SubmitLoanApplication />}
					/>
					<Route
						path={APP_ROUTES.PREMIUM_PAYMENT}
						element={<PaymentScreen />}
					/>
					<Route path={APP_ROUTES.EMAIL} element={<ManageEmailScreen />} />
					<Route
						path={APP_ROUTES.MANAGE_ADDRESS}
						element={<ManageAddressScreen />}
					/>
					<Route path={APP_ROUTES.BANK} element={<ManageBankScreen />} />
					<Route path={APP_ROUTES.NOMINEE} element={<NomineeScreen />} />
					<Route path={APP_ROUTES.EDITNOMINEE} element={<EditNominee />} />
					<Route path={APP_ROUTES.PAYMENTPAGE} element={<PaymentFailed />} />
					{/* <Route path={APP_ROUTES.LEGAL} element={<LegalScreen />} /> */}
					<Route
						path="/payment-feedback/:paymentStatus"
						element={<PaymentFeedbacks />}
					/>
					<Route
						path="/general/payment-feedback/:paymentStatus"
						element={<RenewalPaymentFeedback />}
					/>
					<Route
						path={APP_ROUTES.PROFILE_AR_SCREEN}
						element={<ProfileARscreen />}
					/>
					<Route path={APP_ROUTES.TARGET_AMOUNT} element={<TargetAmount />} />
					<Route
						path={APP_ROUTES.Best_Fit_Combinations}
						element={<BestFitCombinations />}
					/>
					<Route path={APP_ROUTES.Lender_Screen} element={<LenderScreen />} />
					<Route
						path={APP_ROUTES.Select_Financier}
						element={<SelectFinancier />}
					/>
					<Route
						path={APP_ROUTES.Get_Loan_Against_Policy}
						element={<GetLoanAgainstPolicy />}
					/>
					<Route
						path={APP_ROUTES.Policy_Witness_Details}
						element={<PolicyWitnessDetails />}
					/>
					<Route
						path={APP_ROUTES.Policy_Loan_Submit}
						element={<PolicyLoanSubmit />}
					/>
					<Route
						path={APP_ROUTES.Application_Progress}
						element={<ApplicationProgress />}
					/>
					<Route
						path={APP_ROUTES.ADDRESS_CHANGE_RETURN_URL}
						element={<AddressChangeEKYCReturnComponent />}
					/>
					<Route
						path={APP_ROUTES.RENEWAL_PAYMENT_SCREEN}
						element={<RenewalPaymentScreen />}
					/>
					<Route
						path={APP_ROUTES.SELECTBANKDETAILS}
						element={<SelectBankScreen />}
					/>
					<Route
						path={APP_ROUTES.SURRENDER_POLICY}
						element={<ManageSurrenderPolicyScreen />}
					/>
					<Route
						path={APP_ROUTES.MERGE_INFORMATION}
						element={<MergeInformation />}
					/>

					{/* add policy routes */}

					<Route
						path={APP_ROUTES.ADD_POLICY_INSTRUCTION}
						element={<AddPolicyInstructionScreen />}
					/>

					<Route
						path={APP_ROUTES.ADD_POLICY_STEP_ONE}
						element={<StepOneScreen />}
					/>
					<Route
						path={APP_ROUTES.ADD_POLICY_STEP_TWO_INSTRUCTION}
						element={<StepTwoInstructionScreen />}
					/>
					<Route
						path={APP_ROUTES.ADD_POLICY_STEP_TWO}
						element={<StepTwoScreen />}
					/>
					<Route
						path={APP_ROUTES.ADD_POLICY_SUBMITTION_PREVIEW}
						element={<SubmissionPreviewScreen />}
					/>

					<Route
						path={APP_ROUTES.SIGNIN_KYC_RETURN_URL}
						element={<SignupKycReturnComponent />}
					/>

					<Route
						path={APP_ROUTES.SUD_PG}
						element={<PaymentURLScreen />}
					/>

					{!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
						<Route path="*" element={<Navigate to={APP_ROUTES.DASHBOARD} />} />
					) : (
						<></>
					)}
				</Route>
			) : (
				<></>
			)}
		</Routes>
	);
};

export default AppRoutes;
